/*
 * Copyright (C) 2023 SailPoint Technologies, Inc.  All rights reserved.
 */
import { LayoutElement, MfeMetaPosition, RegisterConfig } from './mfe-register.model';

/*
	single-spa-layout

	Please refer the the documentation for layout API and details
	https://single-spa.js.org/docs/layout-definition
*/
export const buildAppRoutesHtml = (
	mfeConfigs: RegisterConfig[],
	{ metaMFEs, staticMFEs }: LayoutElement = {}
): string => {
	let htmlString = '<single-spa-router>';
	htmlString += '<main id="mfe-wrapper">';

	// Top position
	htmlString += '<nav class="mfe-topnav">';
	// Adding metas as position top
	metaMFEs
		?.filter(config => config.position === MfeMetaPosition.Top)
		.forEach(config => {
			htmlString += `<application name="${config.mfeName}"></application>`;
		});
	htmlString += '</nav>';

	// Content position
	htmlString += '<div class="mfe-maincontent">';

	// Adding MFEs
	const metaMFESpecifiers = metaMFEs ? metaMFEs.map(({ mfeName }) => mfeName) : [];
	mfeConfigs
		.filter(config => !metaMFESpecifiers.includes(config.specifier))
		.forEach(config => {
			htmlString += `<application name="${config.specifier}" loader="content"></application>`;
		});

	// Adding static MFEs
	if (staticMFEs?.length > 0) {
		staticMFEs.forEach(config => {
			htmlString += `<application name="${config.pageName}"></application>`;
		});
	}

	// Adding metas with position as content
	metaMFEs
		?.filter(config => config.position === MfeMetaPosition.Content)
		.forEach(config => {
			htmlString += `<application name="${config.mfeName}"></application>`;
		});
	htmlString += '</div>';

	// Bottom position
	// Adding metas with position as bottom
	metaMFEs
		?.filter(config => config.position === MfeMetaPosition.Bottom)
		.forEach(config => {
			htmlString += `<application name="${config.mfeName}"></application>`;
		});

	htmlString += '</main>';
	htmlString += '</single-spa-router>';
	return htmlString;
};

export const buildRouteLoaders = () => ({
	content: `<div class="mfe-loader">
	<div class="loading-indicator">
		<div class="loader-droplet"></div>
		<div class="loader-droplet loader-droplet--reverse"></div>
	</div>
</div>`
});

/*
 * Copyright (C) 2024 SailPoint Technologies, Inc. All rights reserved.
 */
import { ForceAuthRequest } from './app-shell-force-auth.model';
import { AuthCredentialsProvider } from './auth-credential.model';

export class AppShellForceAuthService {
	// this endpoint will help us generating the oauth url
	private initOauthEndpoint = '/oauth/force-auth-init';

	constructor(private authCredentialsService: AuthCredentialsProvider, private csrfToken: string) {}

	/**
	 * Calls to an app shell endpoint which will return the correct redirection url where the user will need to authenticate
	 * @param {ForceAuthRequest} forceAuthRequest - contains the necessary data in order to initiate the force auth process
	 */
	public async forceAuthInit(forceAuthRequest: ForceAuthRequest) {
		const accessToken = (await this.authCredentialsService.getMfeCredentialsV1()).accessToken;
		return new Promise<void>((resolve, reject) => {
			fetch(this.initOauthEndpoint, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'csrf-token': this.csrfToken
				},
				body: JSON.stringify({
					forceAuthRequest,
					accessToken
				})
			})
				.then(response => {
					if (response.status === 200) {
						return response;
					} else {
						reject(
							new Error(
								'Please validate whether the success and failure urls are valid according to our product or the URL that requires authentication is part of our services.'
							)
						);
					}
				})
				.then(response => {
					return response.json().catch(error => {
						reject(new Error(error.message));
					});
				})
				.then(json => {
					if (json && json.forceAuthUrl) {
						window.open(json.forceAuthUrl, forceAuthRequest?.newWindow ? '_blank' : '_self');
						resolve();
					} else {
						reject(new Error("We couldn't generate the force re-authentication URL, please try again."));
					}
				})
				.catch(error => {
					reject(new Error(error.message));
				});
		});
	}
}

/*
 * Copyright (C) 2023 SailPoint Technologies, Inc. All rights reserved.
 */
import { FeatureUser } from '@sailpoint-priv/saas-atlasjs';

import { TLSProduct } from '../../mfe/app-shell/tls/tls.model';

/**
 * Interface describing restrict object properties
 */
interface URLRestriction {
	featureFlagIn?: Array<string>;
	featureFlagNotIn?: Array<string>;
	licenseIn?: Array<string>;
	licenseNotIn?: Array<string>;
	mode?: string;
}

/**
 * Interface describing the properties of a URL object
 */
export interface InternalURL {
	id: string;
	legacyIds: string[];
	product?: string;
	path?: string;
	absoluteUrl?: string;
	restrict?: URLRestriction;
}

/**
 * Interface to use for messaging between different layers
 */
export interface URL {
	id: string;
	legacyIds?: string[];
	absoluteUrl: string;
	newWindow?: boolean;
}

/**
 * Defines the content for URLsSets
 */
export interface URLsSets {
	[index: string]: URL[];
}

/**
 * We set the pending searches types for better reading
 */
export enum PendingSetSearchType {
	SET = 'set',
	URL = 'url'
}

/**
 * Define the pending searches object structure
 */
export interface PendingSetSearch {
	type: PendingSetSearchType;
	setId?: string;
	urlId?: string;
	resolver: (value: URL | PromiseLike<URL> | PromiseLike<URL[]> | URL[]) => void;
}

/**
 * Possible set options
 */
export enum URLSet {
	saasSpRenderer = 'saas-sp-renderer'
}

/**
 * Enum to use for validate ABAC process
 */
export enum URLRestrictions {
	FeatureFlagIn = 'featureFlagIn',
	FeatureFlagNotIn = 'featureFlagNotIn',
	LicenseIn = 'licenseIn',
	LicenseNotIn = 'licenseNotIn',
	Mode = 'mode'
}

/**
 * Enum to use for validate if org uses a dev or prod API
 */
export enum APIURLFragments {
	DevAPIURLFragment = 'api.cloud.sailpoint',
	ProdAPIURLFragment = 'api.identitynow'
}

/**
 * Enum to use for setting possible environments
 */
export enum APIURLEnvironments {
	Prod = 'Prod',
	Dev = 'Dev'
}

/**
 * Request to use for getting the absolute URL
 */
export interface AbsoluteURLValidationRequest {
	internalURL: InternalURL;
	tlsProducts: Array<TLSProduct>;
}

/**
 * Request to use for setting ABAC validation
 */
export interface ABACRequest {
	licenses: Array<string>;
	restrict?: URLRestriction;
	mode: string;
	context: FeatureUser;
}

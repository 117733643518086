/*
 * Copyright (C) 2024 SailPoint Technologies, Inc. All rights reserved.
 */
import { TranslationService } from '../../translation.service';
import { createStaticPage, MfeStaticPage } from '../static-page';
import notFoundContentTemplate from './not-found-content.html';
import notFoundPageTemplate from './not-found-page.html';

/**
 * A static page displayed when an MFE is not matched
 */
export const createNotFoundPage = (translationService: TranslationService): MfeStaticPage => {
	return createStaticPage(
		{
			pageName: 'not-found-page',
			template: notFoundPageTemplate,
			languagePackage: 'APP_SHELL',
			onMount: () => {
				// translate page content
				const pageContent = translationService.applyTranslations(notFoundContentTemplate);
				document.getElementById('not-found-page__content').innerHTML = pageContent;
			}
		},
		translationService
	);
};

/*
 * Copyright (C) 2022 SailPoint Technologies, Inc. All rights reserved.
 */
import { FeatureFlagProvider, LaunchDarklyContextData, MfeTenantContext, MfeUserContext } from './app-shell.model';
import { LDClient, initialize } from 'launchdarkly-js-client-sdk';

export type PartialUserContext = Pick<MfeUserContext, 'alias' | 'id'>;

const NO_CONTEXT = 'no-context';
/**
 * The Feature Flag service is responsible for interaction with
 * the Launch Darkly API.
 */
export class FeatureFlagService implements FeatureFlagProvider {
	/**
	 * This is the launch darkly client that the feature flag service wraps.
	 */
	ldClient: LDClient;

	/**
	 * This indicates if the client has finished initialization
	 */
	ldAvailable = false;

	constructor(tenantContext: MfeTenantContext, ldContext: LaunchDarklyContextData, userContext: MfeUserContext) {
		this.initializeFeatureFlagClient(tenantContext, ldContext, userContext as PartialUserContext);
	}

	/**
	 * Synchronous method to get a boolean feature flag value - available in memory
	 */
	getFeatureFlagBoolean(flagName: string): boolean {
		if (this.ldAvailable) {
			const booleanFeatureFlageValue = this.ldClient.variation(flagName);
			// This is to capture scenarios when ff exists in code base but not in LD
			if (typeof booleanFeatureFlageValue === 'undefined') {
				return booleanFeatureFlageValue;
			}
			if (typeof booleanFeatureFlageValue === 'boolean') {
				return booleanFeatureFlageValue;
			} else {
				throw new Error(
					`getFeatureFlagBoolean can only be used to fetch value for a feature flag which is created as of type boolean in LD`
				);
			}
		} else {
			throw new Error('Launchdarkly client not initialized');
		}
	}

	/**
	 * Synchronous method to get a numeric feature flag value - available in memory
	 */
	getFeatureFlagNumeric(flagName: string): number {
		if (this.ldAvailable) {
			const numericFeatureFlagValue = this.ldClient.variation(flagName);
			// This is to capture scenarios when ff exists in code base but not in LD
			if (typeof numericFeatureFlagValue === 'undefined') {
				return numericFeatureFlagValue;
			}
			if (typeof numericFeatureFlagValue === 'number') {
				return numericFeatureFlagValue;
			} else {
				throw new Error(
					`getFeatureFlagNumeric can only be used to fetch value for a feature flag which is created as of type numeric in LD`
				);
			}
		} else {
			throw new Error('Launchdarkly client not initialized');
		}
	}

	/**
	 * Initialize the launchdarkly client and set boolean to indicate it's available on completion
	 */
	private async initializeFeatureFlagClient(
		tenantContext: MfeTenantContext,
		ldContext: LaunchDarklyContextData,
		userContext: PartialUserContext
	): Promise<void> {
		// target specific users
		const { alias, id: key } = userContext;

		// target orgs and or pods
		const { org, pod } = tenantContext;

		const context = {
			kind: 'user',
			alias: alias || NO_CONTEXT,
			key: key || NO_CONTEXT,
			org: org || NO_CONTEXT,
			pod: pod || NO_CONTEXT
		};

		// the launch darkly context is generated in the backend and rendered as js code.
		// we calculate an initial state of the feature flags in the back end for fast rendering.
		const { clientSideId, featureFlagState: bootstrap, secureUserHash: hash } = ldContext;

		this.ldClient = initialize(clientSideId, context, {
			bootstrap,
			hash
		});
		await this.ldClient.waitUntilReady();
		this.ldAvailable = true;
	}
}

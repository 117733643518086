/*
 * Copyright (C) 2022 SailPoint Technologies, Inc. All rights reserved.
 */

/**
 * This MFE Info model file is a duplicate of the one in the app-shell project.
 * The one in the app-shell project is the source of truth.
 *
 * Eventually, we will have a shared repo where we can pull these models from.
 */

/**
 * A string token that can be used to reference the MFE specific information for MFE props.
 *
 */
export const MFE_INFO_NAME = 'mfe-information';

/**
 * Describes the MFE information specific to each MFE passed from the App Shell to each MFE.
 */
export interface MfeInfo {
	/**
	 * The name of the MFE. Used to identify the MFE on the client.
	 */
	readonly name: string;

	/**
	 * The client side route that was matched to load the MFE.
	 */
	readonly route: string;

	/**
	 * The URL of the source of the code and assets for the MFE
	 */
	readonly url: string;
}

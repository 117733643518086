/*
 * Copyright (C) 2024 SailPoint Technologies, Inc. All rights reserved.
 */
import { APIURLEnvironments, MfeRequestContext } from '../app-shell.model';

export default class ImportMapOverridesService {
	mode: APIURLEnvironments;
	overrideEnabled: boolean;

	constructor(requestContext: MfeRequestContext, featureFlagState: object) {
		this.mode = requestContext?.mode ?? APIURLEnvironments.Prod;
		this.overrideEnabled = featureFlagState ? featureFlagState['PLTUI3596_USE_IMPORT_MAP_OVERRIDES'] : false;
	}

	public isEnabled(): boolean {
		return this.mode === APIURLEnvironments.Dev && this.overrideEnabled;
	}

	public buildUITag() {
		const importMapOverridesTag = document.createElement('import-map-overrides-full');
		importMapOverridesTag.setAttribute('dev-libs', '');
		importMapOverridesTag.setAttribute('trigger-position', 'bottom-left');
		return importMapOverridesTag;
	}
}

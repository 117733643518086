/*
 * Copyright (C) 2023 SailPoint Technologies, Inc. All rights reserved.
 */
import { Branding } from '../branding.model';

/**
 * Get legacy branding stylesheet
 * @param {Branding} brand - Current active brand
 */
export const getLegacyBrandingStylesheet = (brand: Branding) => `
.custom-org-branding-action-bg {
	background-color: ${brand.actionButtonColor} !important;
}

.custom-org-branding-action-bg-hover:hover {
	background-color: ${brand.actionButtonColor} !important;
}

.custom-org-branding-action-bg-lighter-hover:hover {
	background-color: ${brand.actionButtonLighterColor} !important;
}

.custom-org-branding-action-bg-darker {
	background-color: ${brand.actionButtonDarkerColor} !important;
}

.custom-org-branding-action-bg-darker-hover:hover {
	background-color: ${brand.actionButtonDarkerColor} !important;
}

.custom-org-branding-action-icon svg  {
	fill: ${brand.actionButtonColor} !important;
}

.custom-org-branding-action-icon-hover-fill:hover svg {
	fill: ${brand.actionButtonColor} !important;
}

.custom-org-branding-action-icon-focus-fill:focus svg {
	fill: ${brand.actionButtonColor} !important;
}

.custom-org-branding-action-icon-lighter-hover svg:hover {
	fill: ${brand.actionButtonLighterColor} !important;
}

.custom-org-branding-action-border-color {
	border-color: ${brand.actionButtonColor} !important;
}

.custom-org-branding-action-focus-border-color:focus {
	border-color: ${brand.actionButtonColor} !important;
}

.custom-org-branding-action-border-lighter-hover-color:hover {
	border-color: ${brand.actionButtonLighterColor} !important;
}

.custom-org-branding-action-border-bottom-color {
	border-bottom-color: ${brand.actionButtonColor} !important;
}

.custom-org-branding-action-border-bottom-lighter-hover-color:hover {
	border-bottom-color: ${brand.actionButtonLighterColor} !important;
}

.custom-org-branding-action-border-left-color {
	border-left-color: ${brand.actionButtonColor} !important;
}

.custom-org-branding-action-border-left-lighter-hover-color:hover {
	border-left-color: ${brand.actionButtonLighterColor} !important;
}

.custom-org-branding-action-border-right-color {
	border-right-color: ${brand.actionButtonColor} !important;
}

.custom-org-branding-action-border-right-lighter-hover-color:hover {
	border-right-color: ${brand.actionButtonLighterColor} !important;
}

.custom-org-branding-action-border-top-color {
	border-top-color: ${brand.actionButtonColor} !important;
}

.custom-org-branding-action-border-top-lighter-hover-color:hover {
	border-top-color: ${brand.actionButtonLighterColor} !important;
}

.custom-org-branding-action-color {
	color: ${brand.actionButtonColor} !important;
}

.custom-org-branding-action-hover-color:hover {
	color: ${brand.actionButtonColor} !important;
}

.custom-org-branding-action-focus-color:focus {
	color: ${brand.actionButtonColor} !important;
}

.custom-org-branding-action-lighter-hover-color:hover {
	color: ${brand.actionButtonLighterColor} !important;
}

.custom-org-branding-action-lighter-focus-color:focus {
	color: ${brand.actionButtonLighterColor} !important;
}

.custom-org-branding-default-active-darker-color {
	color: ${brand.actionButtonDarkerColor} !important;
}

.custom-org-branding-default-active-darker-border-color {
	border-color: ${brand.actionButtonDarkerColor} !important;
}

.custom-org-branding-link-bg {
	background-color: ${brand.activeLinkColor} !important;
}

.custom-org-branding-link-bg-lighter-hover-color:hover {
	background-color: ${brand.activeLinkLighterColor} !important;
}

.custom-org-branding-link-color {
	color: ${brand.activeLinkColor} !important;
	color-over: ${brand.activeLinkColor} !important;
	color-focus: ${brand.activeLinkColor} !important;
	color-pressed: ${brand.activeLinkColor} !important;
	color-disabled: ${brand.activeLinkColor} !important;
}

.custom-org-branding-nav-bg {
	background-color: ${brand.navigationColor} !important;
}

.custom-org-branding-nav-bg-hover:hover {
	background-color: ${brand.navigationColor} !important;
}

.custom-org-branding-nav-bg-lighter {
	background-color: ${brand.navigationLighterColor} !important;
}

.custom-org-branding-nav-bg-lighter-hover:hover {
	background-color: ${brand.navigationLighterColor} !important;
}

.custom-org-branding-nav-border-color {
	border-color: ${brand.navigationColor} !important;
}

.custom-org-branding-nav-border-lighter-hover-color:hover {
	border-color: ${brand.navigationLighterColor} !important;
}

.custom-org-branding-nav-border-bottom-color {
	border-bottom-color: ${brand.navigationColor} !important;
}

.custom-org-branding-nav-border-bottom-lighter-hover-color:hover {
	border-bottom-color: ${brand.navigationLighterColor} !important;
}

.custom-org-branding-nav-border-left-color {
	border-left-color: ${brand.navigationColor} !important;
}

.custom-org-branding-nav-border-left-lighter-hover-color:hover {
	border-left-color: ${brand.navigationLighterColor} !important;
}

.custom-org-branding-nav-border-right-color {
	border-right-color: ${brand.navigationColor} !important;
}

.custom-org-branding-nav-border-right-lighter-hover-color:hover {
	border-right-color: ${brand.navigationLighterColor} !important;
}

.custom-org-branding-nav-border-top-color {
	border-top-color: ${brand.navigationColor} !important;
}

.custom-org-branding-nav-border-top-lighter-hover-color:hover {
	border-top-color: ${brand.navigationLighterColor} !important;
}

.custom-org-branding-nav-color {
	color: ${brand.navigationColor} !important;
}

.custom-org-branding-nav-lighter-hover-color:hover {
	color: ${brand.navigationLighterColor} !important;
}

.custom-org-branding-nav-lighter-focus-color:focus {
	color: ${brand.navigationLighterColor} !important;
}

.custom-org-branding-nav-stroke-color {
	stroke: ${brand.navigationColor} !important;
}

.custom-org-branding-nav-fill {
	fill: ${brand.navigationColor} !important;
}

.custom-org-branding-nav-fill svg {
	fill: ${brand.navigationColor} !important;
}

.custom-org-branding-nav-hover-fill:hover svg {
	fill: ${brand.navigationColor} !important;
}

.custom-org-branding-nav-focus-fill:focus svg {
	fill: ${brand.navigationColor} !important;
}

.custom-org-branding-nav-lighter-hover-fill {
	fill: ${brand.navigationLighterColor} !important;
}

.custom-org-branding-nav-lighter-border-color {
	border-color: ${brand.navigationLighterColor} !important;
}

.custom-org-branding-action-fill {
	fill: ${brand.actionButtonColor} !important;
}

.custom-org-branding-action-fill svg {
	fill: ${brand.actionButtonColor} !important;
}

.custom-org-branding-action-lighter-hover-fill {
	fill: ${brand.actionButtonLighterColor} !important;
}

.custom-org-branding-link-fill {
	fill: ${brand.activeLinkColor} !important;
}

.custom-org-branding-link-fill svg {
	fill: ${brand.activeLinkColor} !important;
}

.custom-org-branding-link-hover-fill:hover svg {
	fill: ${brand.activeLinkColor} !important;
}

.custom-org-branding-link-focus-fill:focus svg {
	fill: ${brand.activeLinkColor} !important;
}

.custom-org-branding-link-lighter-hover-fill:hover {
	fill: ${brand.activeLinkLighterColor} !important;
}

.custom-org-branding-link-lighter-border-color {
	border-color: ${brand.activeLinkLighterColor} !important;
}

.custom-org-branding-action-after-background-color:after {
	background-color: ${brand.actionButtonColor} !important;
}

.custom-org-branding-action-border-hover-color:hover {
	border-color: ${brand.actionButtonColor} !important;
}
`;

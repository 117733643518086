/*
 * Copyright (C) 2024 SailPoint Technologies, Inc.  All rights reserved.
 */
import { EventType } from './event-bus/event-bus.model';

export interface LayerInput {
	/**
	 * The id of the object being referenced. Example: "9c629efd-7ef1-44be-bf25-6535bf84e7b8"
	 */
	objectId?: string;
	/**
	 * The type of object being referenced. Example: "ENTITLEMENT", "ACCESS_PROFILE", "ROLE", etc...
	 */
	objectType?: string;
	/**
	 * The action to perform on the object being referenced. Example "VIEW", "EDIT", "DELETE", etc...
	 */
	action?: string;
	/**
	 * The HTML Element where the layer should activate within.
	 */
	element?: HTMLElement;
}

export interface LayerOutput extends LayerInput {
	/**
	 * 	The result of the engagement after the layer is deactivated. The API user will define their result codes or strings
	 */
	result: string;
	/**
	 * Whether the layer was successful or not. If the layer was unsuccessful then the error field be provided that provides more detail about why the layer was unsuccessful
	 */
	success?: boolean;
	/**
	 * When the layer was unsuccessful this field is used to provide more details about what the failure was.
	 */
	error?: string;
}

export type Listener = (input: LayerInput) => Promise<LayerOutput>;

export interface AppShellLayerManagerProvider {
	activate: (mfeName: string, layerId: string, input: LayerInput) => Promise<LayerOutput>;
	deactivate: (mfeName: string, layerId: string, input: LayerInput) => Promise<LayerOutput>;
	onActivate: (layerId: string, listener: Listener) => Promise<void>;
	onDeactivate: (layerId: string, listener: Listener) => Promise<void>;
}

export { EventType };

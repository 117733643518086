/*
 * Copyright (C) 2023 SailPoint Technologies, Inc.  All rights reserved.
 */
import { BehaviorSubject } from 'rxjs';

export class PageVisibilityService {
	/**
	 * An subject for the current state of whether the page is visible or not.
	 */
	private hidden$ = new BehaviorSubject<boolean>(false);

	/**
	 * Constructor for the visibility service. If the browser supports the API then visibility
	 * events will be published as an observable.
	 */
	constructor() {
		if (typeof window.document.hidden === 'undefined') {
			// The visibility API is not supported on this browser. It should be supported on all the browsers we support:
			// Chrome 33+, Firefox 18+, IE 10+, Safari 7+. If the browser is older then the page will just always be shown
			// as visible.
			return;
		}

		// Listen for visibility changes.
		window.document.addEventListener('visibilitychange', () => {
			// Register the visibility change for the page.
			this.hidden$.next(window.document.hidden);
		});
	}

	/**
	 * Synchronously answer if the page is currently hidden.
	 */
	public isPageHidden(): boolean {
		return this.hidden$.getValue();
	}

	/**
	 * Listen for changes in the visibility of the page
	 */
	public onVisibilityChange(): BehaviorSubject<boolean> {
		return this.hidden$;
	}
}

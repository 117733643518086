/* istanbul ignore file */

/**
 * Copyright (C) 2022 SailPoint Technologies, Inc. All rights reserved.
 */
import '@angular/localize/init';
import 'core-js/stable';
import * as process from 'process/browser';

/**
 * Required to support Web Animations `@angular/animation`.
 * Needed for: All but Chrome, Firefox and Opera. http://caniuse.com/#feat=web-animation
 */
import 'web-animations-js';
import 'whatwg-fetch';
import 'zone.js';

/**
 * Polly fill the process object to prevent errors about 'process' not being defined.
 * We believe this can be removed after upgrading to Angular 13.
 */
(window as any).process = process;

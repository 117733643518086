/*
 * Copyright (C) 2022 SailPoint Technologies, Inc. All rights reserved.
 */
import { MfeStaticPage } from './views/static-page';

/**
 * RegisterConfig is duplicated the app-shell project and the UI Renderer project
 * so that we don't have to create a shared repo for these models at this time.
 * The registration config for a single-spa MFE.
 * This configuration comes from the AWS DynamoDB table for each published MFE.
 */
export interface RegisterConfig {
	name: string;
	specifier: string;
	routes: string[];
	url: string;
	legacyBranding?: boolean; // whether to render legacy branding classes for this MFE or not
}

// Indicates the position which the meta mfe will be rendered
export enum MfeMetaPosition {
	Top,
	Content,
	Bottom
}
export interface MfeMeta {
	appId: string;
	mfeName: string;
	position: MfeMetaPosition;
}
export interface LayoutElement {
	metaMFEs?: MfeMeta[];
	staticMFEs?: MfeStaticPage[];
}

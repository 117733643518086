import {
	AppShellState,
	AppShellStateProvider,
	AsyncOnMessageFunction,
	Message,
	MessagePayload,
	Topic
} from './app-shell-state.model';
import { EventType } from './event-bus/event-bus.model';
import { EventBusService } from './event-bus/event-bus.service';

export class AppShellStateService implements AppShellStateProvider {
	private state: AppShellState;

	private eventBus: EventBusService;

	constructor() {
		this.eventBus = EventBusService.getInstance();
		this.state = {
			currentUrl: '',
			isNavbarVisible: true
		};
	}

	/**
	 * Subscribe to messages being published in the given topic.
	 * @param topic Name of the channel/topic where messages are published.
	 * @param onMessage Function called whenever new messages on the topic are published.
	 */
	public async on({ topic, onMessage }: { topic: Topic; onMessage: AsyncOnMessageFunction }) {
		this.eventBus.onBroadcast({
			eventType: EventType.APPSHELL_STATE_MANAGER_BROADCAST,
			eventId: topic,
			callback: onMessage
		});
	}

	/**
	 * Publish messages on a topic for all subscribers to receive.
	 * @param topic The topic where the message is sent.
	 * @param message The message to send. Only object format is supported.
	 */
	public async emit({ topic, payload }: Message) {
		await this.set(payload);
		this.eventBus.broadcast({
			eventId: topic,
			eventType: EventType.APPSHELL_STATE_MANAGER_BROADCAST,
			input: payload
		});
	}

	/**
	 * Gets the current state of the app shell.
	 * @returns {Promise<AppShellState>} A promise that resolves with the current state of the app shell.
	 */
	public async get(): Promise<AppShellState> {
		return this.state;
	}

	/**
	 * Patches the state of the app shell
	 * @param newState Merged with the existing state.
	 */
	public async set(newState: MessagePayload) {
		this.state = { ...this.state, ...newState };
	}
}

/*
 * Copyright (C) 2022 SailPoint Technologies, Inc. All rights reserved.
 */

/**
 * Freeze the provided object and all it's children from modification.
 *
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/freeze
 *
 * @param object - The object to freeze
 * @returns The newly frozen object.
 */
export function deepFreeze<T>(object: T): Readonly<T> {
	Object.freeze(object);
	for (const property of Object.getOwnPropertyNames(object)) {
		if (
			// eslint-disable-next-line no-prototype-builtins
			object.hasOwnProperty(property) &&
			object[property] !== null &&
			(typeof object[property] === 'object' || typeof object[property] === 'function') &&
			!Object.isFrozen(object[property])
		) {
			deepFreeze(object[property]);
		}
	}

	return object;
}

/**
 * Produces a deep-frozen shallow copy of an object.
 * @param object - The object to copy
 * @returns The newly copied and frozen object.
 */
export function freezeCopy<T>(object: T): Readonly<T> {
	return deepFreeze({ ...object });
}

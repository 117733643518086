/*
 * Copyright (C) 2023 SailPoint Technologies, Inc. All rights reserved.
 */
import { PendingSetSearch, PendingSetSearchType, URL, URLSet, URLsSets } from '../../src/common/model/url.model';
import { AppShellUrlsProvider, IAppShellUrlServiceArgs } from './app-shell-urls.model';

export class AppShellUrlsService implements AppShellUrlsProvider {
	private URLsSets: URLsSets;

	private pendingSearches: Array<PendingSetSearch> = [];

	constructor({ data }: IAppShellUrlServiceArgs) {
		this.URLsSets = data;
	}

	get urlList() {
		// This is only for backward compatibility with the first version from url service
		return this.URLsSets[URLSet.saasSpRenderer];
	}

	get urlSetList() {
		// This would be the new url list structure
		return this.URLsSets;
	}

	/**
	 * Adds extra navigation url items to the permitted urls navigation
	 *
	 * @param {string} id Set id
	 * @param {URL[]} urls URL data structure
	 * @returns {void}
	 */
	defineUrlSet(id: string, urls: URL[]): void {
		const propertiesValidator = (url: URL, properties: Array<string>) => {
			return properties.map(prop => Object.keys(url).includes(prop) && url?.[prop]).every(Boolean);
		};

		// Validate if there is a set with requested id assigned
		const setFound = this.findSetFromList(id);

		// Validates if structure is partially correct
		if (!setFound) {
			if (urls.every(url => propertiesValidator(url, ['id', 'absoluteUrl']))) {
				// if set doesn't exists, we create a new set
				this.URLsSets[id] = urls;

				if (this.pendingSearches.length > 0) {
					this.resolvePendingSearches();
				}
			} else {
				throw new Error('Error: Please validate you URL structure, should follow the URL interface.');
			}
		} else {
			throw new Error('Error: Set with requested id was already created.');
		}
	}

	/**
	 * Finds the url for a product. An id or legacy id can be passed in.
	 *
	 * @param {string} id The id (or legacy id) of the product.
	 * @returns {string} The absolute URL of the product
	 * @example
	 * const absoluteURL = service.getUrlForId('idn:dashboard');
	 * console.log(absoluteURL); // https://idn.url-for/dashboard
	 */
	getUrlForId(id: string): string {
		if (!this.URLsSets) return null;
		return (
			this.URLsSets?.[URLSet.saasSpRenderer]?.find(url => url.id === id || url.legacyIds?.includes(id))
				?.absoluteUrl ?? null
		);
	}

	/**
	 * Finds the url for a product. An id or legacy id can be passed in.
	 *
	 * @param {string} setId - the set id
	 * @param {string} urlId -  the url id
	 * @returns {Promise<URL | null>} The absolute URL of the product
	 */
	async findUrlById(setId: string, urlId: string): Promise<URL | null> {
		if (!this.URLsSets) return null;
		const setFound = this.findSetFromList(setId);

		return new Promise(resolve => {
			if (setFound) {
				return resolve(this.findUrlFromList(setId, urlId));
			} else {
				this.pendingSearches.push({
					type: PendingSetSearchType.URL,
					setId,
					urlId,
					resolver: resolve
				});
			}
		});
	}

	/**
	 * Get the complete urls from one set
	 *
	 * @param {string} setId - the set id
	 * @returns {Promise<URL[] | null>} The absolute URL of the product
	 */
	async findSetById(setId: string): Promise<URL[] | null> {
		if (!this.URLsSets) return null;
		const setFound = this.findSetFromList(setId);

		return new Promise(resolve => {
			if (setFound) {
				return resolve(setFound);
			} else {
				// We add only the setId without the urlId.
				// This will help the pending searches process to validate what the user is waiting for (a URL or a set of URLs)
				this.pendingSearches.push({
					type: PendingSetSearchType.SET,
					setId,
					resolver: resolve
				});
			}
		});
	}

	/**
	 * Resolves pending searches
	 */
	private resolvePendingSearches() {
		let pendingSearchesCount = this.pendingSearches.length;

		// We iterate backwards for better usage of splice deletion
		while (pendingSearchesCount--) {
			// We set the value
			const search = this.pendingSearches[pendingSearchesCount];

			const setFound = this.findSetFromList(search.setId);
			// If search type is 'url', this means that user is waiting for a specific url (we would need to resolve for findUrlById)
			// If search type is 'set', this means that is waiting for an specific url set (we would need to resolve for findSetById)
			switch (search.type) {
				case PendingSetSearchType.URL: {
					const urlFound = this.findUrlFromList(search.setId, search.urlId);

					// We resolve if the set was created, since it can't be created again
					// Or if URL was found
					if (setFound || urlFound) {
						this.pendingSearches.splice(pendingSearchesCount, 1);
						search.resolver(urlFound);
					}
					break;
				}
				case PendingSetSearchType.SET: {
					if (setFound) {
						this.pendingSearches.splice(pendingSearchesCount, 1);
						search.resolver(setFound);
					}
					break;
				}
			}
		}
	}

	/**
	 * Finds the url set from list
	 *
	 * @param {string} id - the set id
	 * @returns {URL[] | null} URL object
	 */
	private findSetFromList(id: string): URL[] | null {
		return this.URLsSets?.[id] || null;
	}

	/**
	 * Finds the url within sets
	 *
	 * @param {string} setId - the set id
	 * @param {string} urlId -  the url id
	 * @returns {URL | null} URL object
	 */
	private findUrlFromList(setId: string, urlId: string): URL | null {
		return this.URLsSets?.[setId]?.find(url => url.id === urlId || url.legacyIds?.includes(urlId)) || null;
	}
}

export interface AppShellState {
	currentUrl: string;
	isNavbarVisible: boolean;
}

export enum Topic {
	ChangeIsNavbarVisible = 'ChangeIsNavbarVisible',
	ChangeCurrentUrl = 'ChangeCurrentUrl'
}

export type Message =
	| { topic: Topic.ChangeIsNavbarVisible; payload: { isNavbarVisible: boolean } }
	| { topic: Topic.ChangeCurrentUrl; payload: { currentUrl: string } };

export type MessagePayload = Message['payload'];

export type AsyncOnMessageFunction = (payload: MessagePayload) => Promise<void>;
/**
 * Describes the MFE State Manager
 * This manager also acts as an EventBus (pub/sub) provider
 */
export interface AppShellStateProvider {
	emit: (message: Message) => Promise<void>;
	on: ({ topic, onMessage }: { topic: Topic; onMessage: AsyncOnMessageFunction }) => Promise<void>;
	set: (newState: {
		[key in keyof AppShellState]?: AppShellState[key];
	}) => void;
	get: () => Promise<{ [key in keyof AppShellState]?: AppShellState[key] }>;
}

/*
 * Copyright (C) 2023 SailPoint Technologies, Inc.  All rights reserved.
 */

/**
 * An interface for labels that may be associated with a metric.
 */
export interface MetricLabels {
	[index: string]: string;
}

/**
 * An interface that represents a single metric event. All metric
 * types are collapsed into one interface
 */
export interface MetricEvent {
	type: 'COUNT' | 'GAUGE' | 'HISTOGRAM'; // The type of the metric
	name: string; // The unique name of the metric
	help: string; // English description of the metric
	labels?: MetricLabels; // Optional, labels to increase the cardinality of metrics
	// Only for Gauge & Histograpms
	observed?: number;

	// Only for Histograms
	buckets?: number[];
}

/**
 * The list of options to be used when creating a Counter|Gauge|Histogram metric
 */
export interface MetricOptions {
	name: string; // The unique name of the metric
	help: string; // English description of the metric
	labels?: MetricLabels; // Optional, Any custom labels to apply to all metrics observed
	buckets?: number[]; // For histograms only, The pre-defined buckets for this metric.
}

/**
 * An interface that defines the context of the running application
 */
export interface MetricContext {
	// sets the context on what app is loaded for this metric
	app: string;
}

/**
 * An interface that represents any metric type for recording metric events
 */
export class Metric {
	/**
	 * constructor
	 * @param metrics - The sharable observable this metric needs to push new metrics data into
	 * @param context - The context of the running application
	 * @param name - The unique name of the counter
	 * @param help - The english description of the metric
	 * @param labels - Optional, Any custom labels to apply to all events observed
	 * @param buckets - Optional, used only for histograms
	 */
	constructor(
		protected metrics: (metric: MetricEvent) => void,
		protected name: string,
		protected help: string,
		protected labels?: MetricLabels,
		protected buckets?: number[]
	) {}
}

/**
 * retrieves the labels for current metric to be recorded
 * @param labels - the current label string or string arrays
 * @param defaultLabels - the default labels set at the metric creation
 * @param context - context of the running application
 * @returns - the array of labels
 */
function getLabels(labels: MetricLabels, defaultLabels: MetricLabels): MetricLabels {
	const mergedLabels: MetricLabels = {};

	// Second, create any default labels
	for (const name in defaultLabels) {
		// eslint-disable-next-line no-prototype-builtins
		if (defaultLabels.hasOwnProperty(name)) {
			mergedLabels[name] = defaultLabels[name];
		}
	}

	// Lastly, merge any any instance labels.
	for (const name in labels) {
		// eslint-disable-next-line no-prototype-builtins
		if (labels.hasOwnProperty(name)) {
			mergedLabels[name] = labels[name];
		}
	}

	return mergedLabels;
}

/**
 * An individual Histogram metric for recording metric events.
 */
export class Histogram extends Metric {
	/**
	 * Observe the value of an individual event.
	 */
	public observe(value: number, labels?: MetricLabels): void {
		const metricEvent: MetricEvent = {
			type: 'HISTOGRAM',
			name: this.name,
			help: this.help,
			labels: getLabels(labels, this.labels),
			observed: value,
			buckets: this.buckets
		};

		this.metrics(metricEvent);
	}
}

/*
 * Copyright (C) 2022 SailPoint Technologies, Inc. All rights reserved.
 */
import { TranslationService } from '../../translation.service';
import { createStaticPage, MfeStaticPage } from '../static-page';
import sessionExpiredContentTemplate from './session-expired-content.html';
import sessionExpiredPageTemplate from './session-expired-page.html';

/**
 * A static page displayed when a user's session has expired.
 */
export const createSessionExpiredPage = (translationService: TranslationService): MfeStaticPage => {
	return createStaticPage(
		{
			pageName: 'session-expired-page',
			template: sessionExpiredPageTemplate,
			languagePackage: 'APP_SHELL',
			onMount: function (opts) {
				// translate page content
				const pageContent = translationService.applyTranslations(sessionExpiredContentTemplate);
				document.getElementById('session-expired-page__content').innerHTML = pageContent;

				document.getElementById('session-expired-page__button').addEventListener('click', () => {
					window.location.assign(opts.logoutUrl);
				});
			}
		},
		translationService
	);
};

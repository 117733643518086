/*
 * Copyright (C) 2022 SailPoint Technologies, Inc. All rights reserved.
 */
import { MfeAuthCredentials } from './app-shell.model';
import {
	AuthCredentialsProvider,
	FormatException,
	MaxPollCountException,
	MfeTokenRefreshConfig,
	StatusException,
	UnauthorizedException,
	forcedLogoutDelay,
	interactionEventTypes,
	maxPollErrors,
	timerInterval
} from './auth-credential.model';
import { PageVisibilityService } from './page-visibility.service';
import { skip } from 'rxjs';
import { triggerAppChange } from 'single-spa';

/**
 * The AuthCredentialsService is responsible for managing the
 * authorization credentials for the App Shell.
 */
export class AuthCredentialsService implements AuthCredentialsProvider {
	/**
	 * Flag that denotes whether the session has expired.
	 */
	public isSessionExpired = false;

	/**
	 * Calculated configuration for keeping local credentials up to date.
	 */
	private mfeTokenRefreshConfig: Readonly<MfeTokenRefreshConfig>;

	/**
	 * This is a promise for the individual poll. It would be possible to replace this with just a boolean flag.
	 */
	private pollPromise: Promise<boolean>;

	/**
	 * This is a promise for the next credentials refresh.
	 */
	private refreshPromise: Promise<Readonly<MfeTokenRefreshConfig>>;

	/**
	 * Callback used to reject "refreshPromise" with refreshed credentials.
	 */
	private refreshResolve: (value: MfeTokenRefreshConfig) => void;

	/**
	 * Callback used to reject "refreshPromise" when the maximum number of poll errors occurs.
	 */
	private refreshReject: (reason: MaxPollCountException) => void;

	/**
	 * Boolean flag to indicate that the user has interacted with the document in the current polling cycle.
	 */
	private userInteracted = false;

	private refreshUrl: string;
	private logoutUrl: string;
	private intervalId: ReturnType<typeof setInterval>;
	private pollErrorCount = 0;
	private csrfToken: string;

	constructor(
		accessToken: string,
		csrfToken: string,
		refreshInterval: number,
		refreshUrl: string,
		logoutUrl: string,
		pageVisibilityService: PageVisibilityService
	) {
		this.refreshUrl = refreshUrl;
		this.logoutUrl = logoutUrl;
		this.csrfToken = csrfToken;

		// Validate navigation state
		this.validateNavigationState(accessToken, refreshInterval);

		this.startPolling();

		pageVisibilityService
			.onVisibilityChange()
			.pipe(skip(1)) // Ignore the initial state of the page
			.subscribe(hidden => {
				if (!hidden) {
					// Validate session when changing tabs
					this.refreshCredentials();
				}
			});
	}

	/**
	 * First we validate if user has clicked the browser back button after making a redirection
	 *
	 * @param {string} accessToken - token gathered from context
	 * @param {number} refreshInterval - milliseconds on which we need to refresh the token
	 */
	public validateNavigationState(accessToken: string, refreshInterval: number) {
		// we make sure to refresh the token only if browser back button has been clicked after a redirection
		const entries = window.performance.getEntriesByType('navigation');
		if (entries?.length > 0 && (entries[0] as PerformanceNavigationTiming).type === 'back_forward') {
			this.refreshCredentials();
		} else {
			this.updateCredentialData(accessToken, refreshInterval);
		}
	}

	/**
	 * Asynchronously provides valid credentials. If the credentials are currently
	 * valid, returns the current data. If the credentials are currently being refreshed,
	 * returns a promise that resolves when the refresh is complete.
	 * If the current credentials need to be refreshed, starts the process to refresh
	 * the credentials and returns a promise for the refresh process.
	 * Asynchronously provide a valid access token information. If the token is currently
	 * being refreshed, returns a promise that resolves when the refresh is complete.
	 * If the current token needs to be refreshed, starts the process to refresh the token and
	 * returns a promise for the refresh process.
	 * @returns MfeAuthCredentials
	 */
	async getMfeCredentialsV1(): Promise<Readonly<MfeAuthCredentials>> {
		const credentialData = await this.getCredentialData();
		return Object.freeze({ accessToken: credentialData.accessToken });
	}

	/**
	 * Set the isSessionExpired flag to true, which will trigger single-spa
	 * to unmount the current MFE and display the Session Expired page.
	 */
	public handleExpiredSession() {
		this.isSessionExpired = true;
		triggerAppChange();

		setTimeout(() => {
			window.location.assign(this.logoutUrl);
		}, forcedLogoutDelay);
	}

	/**
	 * Initiates a timer to handle polling for the access token refresh. The timer will execute every minute
	 * and check if an interval of time has elapsed. If enough time has not elapsed, the timer will be re-started.
	 * After the interval, the function to call the refresh API is called.
	 */
	private startPolling() {
		this.enableInteractionEventListeners();
		this.intervalId = setInterval(() => {
			// If there have been too many errors, stop polling and throw. and exception.
			if (this.hasTooManyPollErrors() || this.isSessionExpired) {
				this.stopPolling();
				throw this.makeTooManyPollErrors();
			} else if (this.credentialsNeedRefreshed()) {
				// If there is a current polling promise, a refresh is in progress. So skip the refresh this round.
				if (!this.pollPromise) {
					this.refreshCredentials();
				}
			}
		}, timerInterval);
	}

	/**
	 * Stop the timer that checks for refreshing the access token.
	 */
	private stopPolling() {
		this.disableInteractionEventListeners();
		clearInterval(this.intervalId);
	}

	/**
	 * Update the local copy of the credentials. Each time an access token is refreshed,
	 * create a new object with updated time intervals.
	 * @param accessToken string - a valid access token.
	 * @param refreshInterval number - time in milliseconds until the token should be refreshed.
	 * @returns MfeTokenRefreshConfig - token refresh configuration information
	 */
	private updateCredentialData(accessToken: string, refreshInterval: number): Readonly<MfeTokenRefreshConfig> {
		const now = Date.now();

		const newCredentialData = Object.freeze({
			accessToken,
			refreshInterval,
			createdTime: now,
			invalidAfterTime: now + refreshInterval,
			refreshAfterTime: now + refreshInterval / 2
		});

		this.mfeTokenRefreshConfig = newCredentialData;

		return newCredentialData;
	}

	/**
	 * Determine is the credentials need to be refreshed. Has enough time elapsed that
	 * the access token needs to be refreshed.
	 * @returns boolean
	 */
	private credentialsNeedRefreshed(): boolean {
		return (
			!this.mfeTokenRefreshConfig?.refreshAfterTime || this.mfeTokenRefreshConfig.refreshAfterTime < Date.now()
		);
	}

	/**
	 * Determines if the credentials are valid, i.e. not expired. When the credentials created locally,
	 * a time is set after which it is considered invalid. If that time has been set, and has not has elapsed,
	 * it is considered valid.
	 * @returns boolean
	 */
	private credentialsAreValid(): boolean {
		return this.mfeTokenRefreshConfig?.invalidAfterTime && this.mfeTokenRefreshConfig.invalidAfterTime > Date.now();
	}

	/**
	 * Attempts to refresh the access token. If a refreshed access token is fetched, the format
	 * of the result is validated. Then the local credentials are updated. While the promise to
	 * refresh the token is pending, it is assigned to the "refreshPromise" property.
	 * Finally the "refreshPromise" property is cleared to indicate that the process has completed.
	 */
	private async refreshCredentials(): Promise<MfeTokenRefreshConfig> {
		if (!this.refreshPromise) {
			// Create the promise property that indicates that a refresh is being attempted.
			this.refreshPromise = new Promise((resolve, reject) => {
				this.refreshResolve = resolve;
				this.refreshReject = reject;
			});
		}

		// Attempt to refresh, save the result to a property that indicates a poll to the API is in flight.
		this.pollPromise = fetch(this.refreshUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'csrf-token': this.csrfToken
			},
			body: JSON.stringify({
				userInteracted: this.userInteracted
			})
		})
			.then(response => {
				// The fetch polyfill is required to support IE11.  https://www.npmjs.com/package/whatwg-fetch
				if (response.status >= 200 && response.status < 300) {
					return response;
				} else if (response.status >= 400 && response.status < 500) {
					this.throwFetchError(response, UnauthorizedException);
				} else {
					this.throwFetchError(response, StatusException);
				}
			})
			.then(response => {
				// Return the JSON of the response. If the JSON parse fails, consider this a formatting exception.
				// This will be caught by the "catch" below and added to the pollErrorCount.
				return response.json().catch(error => {
					throw new FormatException(error.message);
				});
			})
			.then(json => {
				const { accessToken, refreshInterval } = json;
				if (accessToken && refreshInterval) {
					return { accessToken, refreshInterval };
				} else {
					throw new FormatException('Failed to get an access token on session refresh response.');
				}
			})
			.then(({ accessToken, refreshInterval }) => {
				// Success!

				// Reset error count after a successful refresh.
				this.pollErrorCount = 0;

				// Refreshed credentials have been returned, so refresh the local cached valid credentials
				// and resolve the long running refresh promise with the refreshed credentials.
				this.tryToResolveRefresh(this.updateCredentialData(accessToken, refreshInterval));

				// Then clear the long running refresh promise because it has been resolved.
				this.clearRefreshPromise();
				return true;
			})
			.catch(error => {
				// Failure
				// This promise should never reject.
				// Catch any errors for this promise. Either ignore the error or add to the total of tracked errors.
				// Errors that are returned from the API are counted in order to limit the amount of time
				// the client spends polling in an error state.

				if (this.isCountedPollError(error)) {
					this.pollErrorCount += 1;
				}

				if (error instanceof UnauthorizedException) {
					this.handleExpiredSession();
				}

				return false;
			})
			.finally(() => {
				// Clear the property that indicates that the refresh fetch is in flight.
				this.pollPromise = null;

				// Now check if there have been too many errors reported from failed fetches.
				if (this.hasTooManyPollErrors() || this.isSessionExpired) {
					// Only when we reach our maximum error count do we reject the refreshPromise.
					this.tryToRejectRefresh();

					// Now clear the long running refresh promise because it has been rejected.
					this.clearRefreshPromise();
				}

				this.userInteracted = false;
				this.enableInteractionEventListeners();
			});
		// Return the promise property that indicates that a refresh is being attempted.
		return this.refreshPromise;
	}

	/**
	 * Clear the long running refresh promise as well as it's resolver and rejector callbacks.
	 */
	private clearRefreshPromise(): void {
		this.refreshPromise = null;
		this.refreshResolve = null;
		this.refreshReject = null;
	}

	/**
	 * Attempt to resolve the long running refresh promise, if its resolver callback is assigned.
	 */
	private tryToResolveRefresh(value: MfeTokenRefreshConfig): void {
		if (this.refreshResolve) {
			this.refreshResolve(value);
		}
	}

	/**
	 * Attempt to reject the long running refresh promise, if its rejector callback is assigned.
	 */
	private tryToRejectRefresh(): void {
		if (this.refreshReject) {
			this.refreshReject(this.makeTooManyPollErrors());
		}
	}

	/**
	 * Checks if an error should be counted as a polling error.
	 * Errors that are returned from the API are counted in order to limit the amount of time
	 * the client spends polling in an error state.
	 * @param error Error - error caught by the refresh fetch
	 */
	private isCountedPollError(error: Error): boolean {
		return error instanceof StatusException || error instanceof FormatException;
	}

	/**
	 * Utility function to evaluate if too many errors have occurred while
	 * polling to refresh credentials.
	 */
	private hasTooManyPollErrors(): boolean {
		return this.pollErrorCount > maxPollErrors;
	}

	/**
	 * Utility function to make an exception when the maximum number of poll errors has excepted.
	 * @returns MaxPollCountException - created with a message for too may poll errors
	 */
	private makeTooManyPollErrors(): MaxPollCountException {
		return new MaxPollCountException(
			`Failed to refresh authentication token after ${this.pollErrorCount} failed attempts.`
		);
	}

	/**
	 * Throw and exception for a failed fetch. The error message includes the fetched URL and the error text.
	 * @param response - HTTP response
	 * @param ErrClass - Error class constructor
	 */
	private throwFetchError(response: Response, ErrClass: { new (arg: string) }): void {
		throw new ErrClass(
			`Failed to fetch refreshed token from ${this.refreshUrl} with status ${response.statusText}`
		);
	}

	/**
	 * Asynchronously provides valid credentials. If the credentials are currently
	 * valid, returns the current data. If the credentials are currently being refreshed,
	 * returns a promise that resolves when the refresh is complete.
	 * If the current credentials need to be refreshed, starts the process to refresh
	 * the credentials and returns a promise for the refresh process.
	 * Asynchronously provide a valid access token information. If the token is currently
	 * being refreshed, returns a promise that resolves when the refresh is complete.
	 * If the current token needs to be refreshed, starts the process to refresh the token and
	 * returns a promise for the refresh process.
	 * @returns MfeTokenRefreshConfig - object that contains local credential data
	 */
	private getCredentialData(): Promise<MfeTokenRefreshConfig> {
		if (!this.refreshPromise && this.credentialsAreValid()) {
			return Promise.resolve(this.mfeTokenRefreshConfig);
		}
		if (this.pollPromise && this.refreshPromise) {
			return this.refreshPromise;
		} else {
			return this.refreshCredentials();
		}
	}

	/**
	 * Begin listening for user interaction events.
	 */
	private enableInteractionEventListeners = () =>
		interactionEventTypes.forEach(eventType => document.addEventListener(eventType, this.handleInteractionEvent));

	/**
	 * Stop listening for user interaction events.
	 */
	private disableInteractionEventListeners = () =>
		interactionEventTypes.forEach(eventType =>
			document.removeEventListener(eventType, this.handleInteractionEvent)
		);

	/**
	 * Mark that a user interaction event has occurred within the current polling cycle.
	 */
	private handleInteractionEvent = () => {
		this.userInteracted = true;
		this.disableInteractionEventListeners();
	};
}

/*
 * Copyright (C) 2023 SailPoint Technologies, Inc. All rights reserved.
 */
import { AppShellNavigationProvider, MfeRequestContext } from './app-shell.model';
import * as singleSpa from 'single-spa';

export class AppShellNavigationService implements AppShellNavigationProvider {
	constructor(private requestContext: MfeRequestContext) {}

	/**
	 * Method used for navigating within mfe environment
	 * Extra logic could be added in here like loading mask, etc.
	 *
	 * @param {string} url string
	 * @param {string?} newWindow string
	 */
	public navigateToUrl(url: string, newWindow?: boolean): void {
		// Stop when url is not set
		if (!url) {
			throw new Error('Error: Missing url variable, either is empty or is not set.');
		}

		if (newWindow) {
			// Only when it is required a blank page
			window.open(url, '_blank');
		} else {
			const isPathExcluded: boolean =
				this.requestContext.excludePaths?.map(path => url.includes(path)).some(Boolean) ?? false;
			const isInCurrentAppShell: boolean = url.includes(this.requestContext.appShellBaseUrl);
			if (isInCurrentAppShell && !isPathExcluded) {
				// Triggers external link, within mfe and between mfe's navigation
				singleSpa.navigateToUrl(url);
			} else {
				// Triggers this scenario when new url is not in the same appshell
				window.open(url, '_self');
			}
		}
	}
}

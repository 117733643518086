/*
 * Copyright (C) 2022 SailPoint Technologies, Inc. All rights reserved.
 */
import { MfeAuthCredentials } from './app-shell.model';

/**
 * Interval in milliseconds for the polling timer.
 */
export const timerInterval = 1000 * 60;

/**
 * The maximum amount of time in milliseconds before a user is redirected to the login page
 * after their session has expired.
 */
export const forcedLogoutDelay = 1000 * 60 * 10;

/**
 * Maximum number of polling errors to allow before stopping the time. 15 minutes worth of poll attempts.
 */
export const maxPollErrors = (1000 * 60 * 15) / timerInterval;

/**
 * Types of events that denote user interaction.
 */
export const interactionEventTypes = ['pointermove', 'keydown', 'touchstart'];

/**
 * An exception thrown when an unexpected response status is received.
 */
export class StatusException extends Error {
	// This is to support instanceof in es5, can be removed when we target a more recent version
	constructor(message: string) {
		super(message);
		Object.setPrototypeOf(this, StatusException.prototype);
	}
}

/**
 * An exception thrown when an 4XX response status is received.
 */
export class UnauthorizedException extends Error {
	// This is to support instanceof in es5, can be removed when we target a more recent version
	constructor(message: string) {
		super(message);
		Object.setPrototypeOf(this, UnauthorizedException.prototype);
	}
}

/**
 * An exception thrown when JSON is received in an unexpected format.
 */
export class FormatException extends Error {
	// This is to support instanceof in es5, can be removed when we target a more recent version
	constructor(message: string) {
		super(message);
		Object.setPrototypeOf(this, FormatException.prototype);
	}
}

/**
 * An exception thrown when when the maximum number of poll errors have occurred.
 */
export class MaxPollCountException extends Error {
	// This is to support instanceof in es5, can be removed when we target a more recent version
	constructor(message: string) {
		super(message);
		Object.setPrototypeOf(this, MaxPollCountException.prototype);
	}
}

/**
 * The provider of auth credentials.
 */
export interface AuthCredentialsProvider {
	getMfeCredentialsV1(): Promise<Readonly<MfeAuthCredentials>>;
}

/**
 * Information about an access token. This information is used to supply
 * a valid access token to MFEs.
 * This represents the data returned from the session refresh API.
 */
export interface MfeAccessToken {
	/**
	 * The JWT access token
	 */
	accessToken: string;

	/**
	 * Interval in milliseconds after which the client should request a refreshed access token.
	 */
	refreshInterval: number;
}

/**
 * Calculated configuration for keeping local credentials up to date.
 */
export interface MfeTokenRefreshConfig extends MfeAccessToken {
	/**
	 * Date the token was created on the client. This is supplied
	 * by the local context in order to avoid and inconsistencies with server time.
	 * Used to calculate expiration and refresh dates for local credentials.
	 */
	createdTime: number;

	/**
	 * The unix epoch time after which a token is considered invalid (expired.)
	 */
	invalidAfterTime: number;

	/**
	 * The unix epoch time after which the client should attempt to refresh the local credentials.
	 */
	refreshAfterTime: number;
}

/*
 * Copyright (C) 2024 SailPoint Technologies, Inc.  All rights reserved.
 */
import {
	AppShellEventBusProvider,
	BroadcastListener,
	BroadcastParams,
	ListenerRegisterReturn,
	OnBroadcastParams,
	OnUnicastParams,
	PendingEvent,
	UnicastListener,
	UnicastParams
} from './event-bus.model';
import { checkActivityFunctions } from 'single-spa';

const objectMatchesAttributes =
	({ mfeName, eventId, eventType }) =>
	listener => {
		return listener.mfeName === mfeName && listener.eventType === eventType && listener.eventId === eventId;
	};

/**
 * Represents a class coordinating messages between MFEs
 */
export class EventBusService implements AppShellEventBusProvider {
	private static _singleton = new EventBusService();
	private broadcastListeners: BroadcastListener[] = [];

	private unicastListeners: UnicastListener[] = [];

	private pendingUnicastEvents: PendingEvent[] = [];

	private constructor() {}

	/**
	 * Get an instance of the event bus
	 * @example
	 * EventBusService.getInstance();
	 * @returns {EventBusService} eventBus - The singleton instance
	 */
	static getInstance() {
		return EventBusService._singleton;
	}

	/**
	 * Register a listener to be called when broadcasting messages.
	 * @param {Object} params
	 * @param {EventType} eventType - The defined type of the broadcast, e.g: LAYER_ACTIVATE
	 * @param {string} eventId - The unique identifier of the channels this message will go to, eg: "navbar"
	 * @param {Callback} callback - The listening function that will be executed if the type and id match a broadcast message
	 */
	async onBroadcast({ eventType, eventId, callback }: OnBroadcastParams): Promise<ListenerRegisterReturn> {
		const listener: BroadcastListener = {
			eventType,
			eventId,
			callback,
			cancel: async () => {
				this.broadcastListeners = this.broadcastListeners.filter(l => l !== listener);
			}
		};
		this.broadcastListeners.push(listener);
		return { cancel: listener.cancel };
	}

	/**
	 * Register a UNIQUE listener to be called when unicasting messages. The listener will return promised data to the
	 * caller. Also deal with pending unicast messages that were sent before the listener is registered
	 * @param {Object} params
	 * @param {string} mfeName - The MFE where the unicast layer is defined
	 * @param {EventType} eventType - The defined type of the broadcast, e.g: LAYER_ACTIVATE
	 * @param {string} eventId - The unique identifier of the channel , eg: "chatbot-dialog"
	 * @param {Callback} callback - The listening function that will be executed if the mfeName, eventType and eventId match the message
	 */
	async onUnicast({ mfeName, eventType, eventId, callback }: OnUnicastParams): Promise<ListenerRegisterReturn> {
		// find if there's already a unicast listener for the parameters
		const alreadyRegistered = this.unicastListeners.find(objectMatchesAttributes({ mfeName, eventId, eventType }));
		if (alreadyRegistered) {
			throw new Error(`Unicast listener already registered. MFE: ${mfeName}; id: ${eventId}; type: ${eventType}`);
		}
		// if not found, it's a new unique listener; register it
		const listener = {
			mfeName,
			eventType,
			eventId,
			callback,
			cancel: async () => {
				this.unicastListeners = this.unicastListeners.filter(l => l !== listener);
			}
		};
		this.unicastListeners.push(listener);
		// check if there are pending messages:
		// events matching the params will be set in the pendingEvents local variable
		// and removed from the instance variable this.pendingUnicastEvents
		const pendingEvents: PendingEvent[] = this.pendingUnicastEvents
			.filter(objectMatchesAttributes({ mfeName, eventId, eventType }))
			.reduce((acc, value) => {
				this.pendingUnicastEvents.splice(this.pendingUnicastEvents.indexOf(value), 1);
				return acc.concat(value);
			}, []);

		// execute the callback for every pending event and resolve
		for (const event of pendingEvents) {
			try {
				const output = await callback(event.input);
				event.resolve(output);
			} catch (error) {
				event.reject(error);
			}
		}

		return { cancel: listener.cancel };
	}

	/**
	 * Broadcast messages can execute many listeners. No promise is expected. Messages can be lost.
	 * @param eventId Optional
	 * @param data
	 * @param eventType
	 */
	async broadcast({ eventType, eventId, input }: BroadcastParams) {
		for (const listener of this.broadcastListeners) {
			if (listener.eventType === eventType && listener.eventId === eventId) {
				listener.callback(input);
			}
		}
	}

	/**
	 * Sends a unicast message. A unicast message has only one receiving listener, allowing a contract where the listener
	 * can return data
	 * @param {Object} params
	 * @param {string} params.mfeName The MFE receiving the message
	 * @param {string} params.eventId The unique identifier of the channel receiving the message
	 * @param {EventType} params.eventType The defined type of the message, e.g: LAYER_ACTIVATE
	 * @param {EventInput} params.inputData The data that the listener will consume/ use
	 * @returns A promise with output data
	 */
	async unicast({ mfeName, eventType, eventId, input }: UnicastParams): Promise<object> {
		const isActive = await this.isMfeActive(mfeName);
		if (!isActive) {
			throw new Error(`Can't send messages to non-active MFE: ${mfeName}`);
		}

		const found = this.unicastListeners.find(objectMatchesAttributes({ mfeName, eventId, eventType }));

		if (found) {
			return found.callback(input);
		}

		// edge: if a unicast message is sent before any listener has been registered (race conditions); we need to make sure we will
		// respond back to the message by returning a temporary promise that will be resolved once the listener is registered.
		return this.addPendingEvents({ mfeName, eventType, eventId, input });
	}

	/**
	 *  Utility method pushing a pending event into an array in the instance as a test helper
	 * The Array keeps track of a series of objects containing a Promise's resolve/reject methods
	 * @param {Object} params
	 * @param {string} params.mfeName The MFE receiving the message
	 * @param {string} params.eventId The unique identifier of the channel receiving the message
	 * @param {EventType} params.eventType The defined type of the message, e.g: LAYER_ACTIVATE
	 * @param {EventInput} params.inputData The data that the listener will consume/ use
	 * @returns  {Promise} a Promise that the service will resolve/reject later
	 */
	private addPendingEvents({ mfeName, eventType, eventId, input }): Promise<object> {
		return new Promise((resolve, reject) => {
			this.pendingUnicastEvents.push({
				mfeName,
				eventType,
				eventId,
				input,
				resolve,
				reject
			});
		});
	}

	/**
	 * Ask SngleSpa if the mfe should be active within the current location
	 * https://single-spa.js.org/docs/api/#checkactivityfunctions
	 * @param mfeName
	 * @returns
	 */
	private async isMfeActive(mfeName: string, location = window.location): Promise<boolean> {
		// As per singelSPA: "Will call every app's activity function with url and give you list of which applications should be mounted with that location."
		const activeMfes = checkActivityFunctions(location);
		return activeMfes.includes(mfeName);
	}
}

/*
 * Copyright (C) 2022 SailPoint Technologies, Inc. All rights reserved.
 */
import { PendoContextData } from './app-shell.model';

/* global pendo */
/**
 * This service is responsible for run the script for Pendo
 * and initilize it with the user and org information requiered
 */

export class PendoService {
	/**
	 * The constructor receive the pendoData and the dom object
	 */
	public pendoData: PendoContextData;
	constructor(pendo: PendoContextData, document: Document) {
		this.pendoData = pendo;
		this.runPendoScript(document);
	}

	/**
	 * Run the script with the api key from Pendo and then finally
	 * initialize it with the information related to the user and the org
	 */
	private runPendoScript(document: Document) {
		if (!this.pendoData) {
			return;
		}

		let v, w, x, y, z;
		(function (apiKey) {
			(function (p, e, n, d, o) {
				o = p[d] = p[d] || {};
				o._q = [];
				v = ['initialize', 'identify', 'updateOptions', 'pageLoad'];
				for (w = 0, x = v.length; w < x; ++w)
					(function (m) {
						o[m] =
							o[m] ||
							function (...args) {
								o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(args, 0)));
							};
					})(v[w]);
				y = e.createElement(n);
				y.async = !0;
				y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
				z = e.getElementsByTagName(n)[0];
				z.parentNode.insertBefore(y, z);
			})(window, document, 'script', 'pendo');
		})(this.pendoData.pendoApiKey);
		pendo.initialize({ visitor: this.pendoData.visitor as never, account: this.pendoData.account as never });
	}
}

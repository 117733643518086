/*
 * Copyright (C) 2023 SailPoint Technologies, Inc.  All rights reserved.
 */
import { MetricService } from './metrics.service';

export interface HistogramProperties {
	metricName: string;
	help: string;
	buckets?: number[];
}

interface ObservableParams {
	timestamp?: number;
}

export const BUCKETS = [0, 2, 4, 8, 10, 15, 30, 60, 90];

/*
 * Reports to UMS per each MFE app.
 * It is important to check the lifecycle of single-spa events
 */
export default class MFEAppMonitor {
	private _beforeAppChange: number;

	constructor(private name: string, private metricService: MetricService) {
		this.metricService = metricService;
		this.name = name;
	}

	/*
	 * Keep track of when Single-SPA started mounting. It will be of use for both,
	 * AppLaunch and subsequent modes
	 */
	public beforeAppChange(timestamp: number = performance.now()) {
		// keep the timestamp of single-spa change start so we can now how much it will take for
		// each MFE to mount
		this._beforeAppChange = timestamp;
	}

	/**
	 * Called after the router has finished routing to a new location.
	 *
	 * @param {boolean} isInitialLoad Set it to true whenever calling this method on app launch mode.
	 * App Launch is when we first load a page (resources, painting, etc)
	 * @param {number} timestamp The timestamp of the routing event.
	 */
	public afterRouting({ timestamp }: ObservableParams) {
		// this timestamp is set by the outermost client, which is the window performance API. It can have
		// really big values in subsequent mount/unmounts but we only care about it in the 'app launch' mode.
		const totalTimeToLoad = timestamp;
		const mfeTimeToMount = totalTimeToLoad - this._beforeAppChange;

		this.buildHistogram({
			metricName: `ui_common_mfe_mount_seconds`,
			help: 'The time it took for the MFE router to mount/unmount this MFE app.'
		}).observe(mfeTimeToMount / 1000, { app: `MFE_${this.name}` });
	}

	private buildHistogram({ metricName, help, buckets = BUCKETS }: HistogramProperties) {
		return this.metricService.createHistogram({
			buckets,
			help,
			name: metricName
		});
	}
}

/*
 * Copyright (C) 2023 SailPoint Technologies, Inc. All rights reserved.
 */
import { MfeRequestContext } from '../app-shell.model';
import { RegisterConfig } from '../mfe-register.model';
import { getLegacyBrandingStylesheet } from './legacy-stylesheet';

/**
 * This class to generate the legacy branding stylesheet scoped to only MFEs opted-in
 */
export class LegacyBrandingService {
	constructor(private requestContext: MfeRequestContext, private mfeConfigs: RegisterConfig[]) {}

	/**
	 * Initialize the legacy branding stylesheet and insert in the DOM if any of the MFEs are opted-in
	 */
	public async appendLegacyBrandingStylesheet(): Promise<void> {
		// find if there is any MFE interested in legacy branding
		const optedInMfes = this.mfeConfigs.filter(config => config.legacyBranding === true);
		if (!optedInMfes || optedInMfes.length === 0) {
			return;
		}

		// scope all classes to only opted-in MFEs
		const mfeSelectorPrefix = `single-spa-application\\:`;
		const scopedClassNames = optedInMfes.map(config => `#${mfeSelectorPrefix}${config.name} $1`).join(', ');

		// get legacy branding stylesheet filled with current brand's values
		const stylesheetTemplate = getLegacyBrandingStylesheet(this.requestContext.brand);

		// create style element
		const brandingStyleElement = document.createElement('style');

		// replace all class names with scoped ones for opted-in MFEs
		// ex:  if cloud-ui-auth & cloud-ui-reset MFEs are in,
		// 		then branding class should be
		// 			`#single-spa-application\:cloud-ui-auth .custom-org-branding-action-bg , #single-spa-application\:cloud-ui-reset .custom-org-branding-action-bg`
		//		instead of just
		// 			`.custom-org-branding-action-bg`
		brandingStyleElement.innerHTML = stylesheetTemplate.replace(/(\.[\w\-:\s]*)/g, scopedClassNames);

		// append to the DOM
		document.head.append(brandingStyleElement);
	}
}

/*
 * Copyright (C) 2024 SailPoint Technologies, Inc.  All rights reserved.
 */

export enum EventType {
	LAYER_ACTIVATE = 'layer_activate',
	LAYER_DEACTIVATE = 'layer_deactivate',
	APPSHELL_STATE_MANAGER_BROADCAST = 'appshell-state-broadcast'
}

export type Callback = (input: object) => Promise<object>;
export type BroadcastCallback = (input: object) => Promise<void>;
interface ListenerBaseParams {
	eventType: EventType;
}

export interface OnBroadcastParams extends ListenerBaseParams {
	eventId?: string;
	callback: BroadcastCallback;
}

export interface OnUnicastParams extends ListenerBaseParams {
	eventId: string;
	mfeName: string;
	callback: Callback;
}
export interface BroadcastParams {
	input: object;
	eventId?: string;
	eventType: EventType;
}

export interface UnicastParams extends BroadcastParams {
	mfeName: string;
}

export interface BaseListener {
	mfeName?: string;
	eventId?: string;
	eventType: EventType;
	cancel: () => Promise<void>;
}
export interface UnicastListener extends BaseListener {
	callback: Callback;
}

export interface BroadcastListener extends BaseListener {
	callback: BroadcastCallback;
}

export type ListenerRegisterReturn = Pick<BaseListener, 'cancel'>;
export interface AppShellEventBusProvider {
	broadcast: ({ eventType, eventId, input }: BroadcastParams) => Promise<void>;
	unicast: ({ mfeName, eventType, eventId, input }: UnicastParams) => Promise<object>;
	onBroadcast: ({ eventType, eventId, callback }: OnBroadcastParams) => Promise<ListenerRegisterReturn>;
	onUnicast: ({ mfeName, eventType, eventId, callback }: OnUnicastParams) => Promise<ListenerRegisterReturn>;
}

export interface PendingEvent {
	mfeName?: string;
	eventId?: string;
	eventType: EventType;
	input: object;
	/* eslint-disable-next-line "@typescript-eslint/no-explicit-any" */
	resolve: any;
	/* eslint-disable-next-line "@typescript-eslint/no-explicit-any" */
	reject: any;
}

/*
 * Copyright (C) 2024 SailPoint Technologies, Inc.  All rights reserved.
 */
import { AppShellLayerManagerProvider, EventType, LayerInput, LayerOutput } from './app-shell-layer-manager.model';
import { EventBusService } from './event-bus/event-bus.service';
import { MfeInfo } from './mfe-info.model';

export type LayerCallback = (input: LayerInput) => Promise<LayerOutput>;
/**
 * This class is a wrapper around the EventBus service exposed by the appshell.
 * - Simplifies the interaction with the appshell by knowing the MFE's name, obtained
 * from the injected mfeInfo
 * - Ensures there's no race condition on obtaining and calling methods in the event bus.
 */
export class LayerManagerService implements AppShellLayerManagerProvider {
	private mfeName: string;
	private eventBus: EventBusService;

	constructor(mfeInfo: MfeInfo) {
		this.mfeName = mfeInfo.name;
		this.eventBus = EventBusService.getInstance();
	}

	/**
	 * Activate a layer. A layer is a micro-frontend that can be enabled "ontop" of the existing MFEs on the page.
	 * Each MFE may have multiple layers each identified by a layerId.
	 * Activation can mean different things depending on the specific layer being activated,
	 * such as showing a dialog box, or a button.
	 * @param {String} mfeName - The name of the MFE that is responsible for the layer.
	 * @param {String} layerId - The unique identifier for the layer within the designated MFE.
	 * @param {LayerInput} eventInput - The data being provided to the new layer
	 * @returns {Promise<LayerOutput>} - A data object (spec pending)
	 */
	public async activate(mfeName: string, layerId: string, input: LayerInput): Promise<LayerOutput> {
		const response = await this.eventBus.unicast({
			mfeName,
			eventId: layerId,
			eventType: EventType.LAYER_ACTIVATE,
			input
		});
		return response as object as LayerOutput;
	}

	/**
	 * Deactivate a layer. A layer is a micro-frontend that can be enabled "ontop" of the existing MFEs on the page.
	 * Each MFE may have multiple layers each identified by a layerId.
	 * Deactivation can mean different things depending on the specific layer being deactivated,
	 * such as hiding a dialog box, or removing a button from the DOM.
	 * @param {String} mfeName - The name of the MFE that is responsible for the layer.
	 * @param {String} layerId - The unique identifier for the layer within the designated MFE.
	 * @param {LayerInput} eventInput - The data being provided to the layer
	 * @returns {Promise<LayerOutput>} - A data object (spec pending)
	 */
	public async deactivate(mfeName: string, layerId: string, input: LayerInput): Promise<LayerOutput> {
		const response = await this.eventBus.unicast({
			mfeName,
			eventId: layerId,
			eventType: EventType.LAYER_DEACTIVATE,
			input
		});
		return response as object as LayerOutput;
	}

	/**
	 * Listen when a layer is activated.
	 * The listener uses a single way channel communication. Only one listener can be set per layer due to the
	 * unicast nature of the undrlying comm channel.
	 * @param {String} layerId - The unique identifier for the layer within the  MFE.
	 * @param {Callback} listener - A callback that is called once the activation message is received. It has a 'data' parameter
	 * that can be consumed
	 */
	public async onActivate(layerId: string, listener: LayerCallback) {
		this.eventBus.onUnicast({
			mfeName: this.mfeName,
			eventType: EventType.LAYER_ACTIVATE,
			eventId: layerId,
			callback: listener
		});
	}

	/**
	 * Listen when a layer is deactivated.
	 * The listener uses a single way channel communication. Only one listener can be set per layer due to the
	 * unicast nature of the undrlying comm channel.
	 * @param {String} layerId - The unique identifier for the layer within the MFE.
	 * @param {Callback} listener - A callback that is called once the deactivation message is received. It has a 'data' parameter
	 * that can be consumed
	 */
	public async onDeactivate(layerId: string, listener: LayerCallback) {
		this.eventBus.onUnicast({
			mfeName: this.mfeName,
			eventType: EventType.LAYER_DEACTIVATE,
			eventId: layerId,
			callback: listener
		});
	}
}
